
li{
    list-style: none;

 }

a{
color: black;

}
a:hover{
    color:#3500D3;
    
    font-size: 18px;
}