
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', sans-serif;
  text-transform:none;
}

.home,
.About,
.services,
.Specialities,
.contact-us,
.Appoinments,
.marketing,
.consulting {
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
p{
  text-transform: none;
}
.home {
 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
  font-size: 100px;

}

.About {
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: red;

  font-size: 100px;
}

.services {
  
  background-position: center;
 
  background-repeat: no-repeat;
  color: red;
  font-size: 30px;
}

.Specialities {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.Appoinments {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

#preloader{
background: #000 url('./images/guanxian.gif') no-repeat center center;
height: 100vh;
width: 100%;
position: fixed;
z-index: 100;
}