
 *, *::before, *::after {
	 box-sizing: border-box;
}
 .card {
	 position: relative;
	 display: block;
	 width: 640px;
	 height: 450px;
	 margin: 0;
	 margin-top: 50px;
	 margin-bottom: 50px;
	 color: black;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 overflow: hidden;
	 outline: none;
	 animation: slide-in 1s backwards;
	
}
 .card:last-child {
	 animation-delay: 0.4s;
}
 @keyframes slide-in {
	 from {
		 opacity: 0;
		 transform: translateY(30px);
	}
}
 .card.dark {
	 color: black;
}
 .card.dark::before {
	background-color:aqua;
	color: #00001f;

}
 .card::before {
	 content: "";
	 position: absolute;
	 z-index: -1;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	background-color:aqua;
	color: #00001f;
	 opacity: 0.1;
	 transition: 0.5s;
}
 .card:hover::before, .card:focus::before {
	 opacity: 0.4;
}
 .box {
	 width: 450px;
	 padding: 30px;
	 position: relative;
	 font-size: 22px;
	 line-height: 1.6;
	 transition: 0.6s;
}
 .card:hover .box, .card:focus .box {
	 transform: translateY(-20px);
}
 .text {
	 
	 margin: 0;
	 transform: scale(0.9);
	 transition: 0.5s;
	 text-align: justify;
	 text-transform: none;
}
 .card:hover .text, .card:focus .text {
	 transform: none;
}
 .cite {
	 position: absolute;
	 left: 50%;
	 top: 0;
	 transform: translate(-50%, 100%);
	 transition: transform 0.2s 0.075s;
	 font-size: 20px;

}
 .card:hover .cite, .card:focus .cite {
	 transition-delay: 0.5s, 0.575s;
	 letter-spacing: 0em;
	 transform: translate(-50%, 20px);
}
 .box::before, .box-side-borders::before, .box::after, .box-side-borders::after {
	 content: "";
	 position: absolute;
	 pointer-events: none;
	 transition: 0.5s 0.1s;
	 background: currentColor;
	 
}
 .box::before {
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 2px;
}
 .box::after {
	 bottom: 0;
	 left: 0;
	 width: 100%;
	 height: 2px;
	 
}
 .card:hover .box::before, .card:focus .box::before {
	 width: 390px;
	 transform: translate(30px, calc(1.6em - .1em + 32px));
}
 .card:hover .box::after, .card:focus .box::after {
	 width: 390px;
	 transform: translate(30px, calc(.1em - 30px));
}
 .box-side-borders::before, .box-side-borders::after {
	 transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out;
}
 .box-side-borders::before {
	 bottom: 0;
	 left: 0;
	 width: 2px;
	 height: 100%;
	 transform-origin: bottom;
}
 .box-side-borders::after {
	 top: 0;
	 right: 0;
	 width: 2px;
	 height: 100%;
	 transform-origin: top;
}
 .card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	 height: 390px;
	 transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out;
}
 .card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
	 transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
}
 .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	 transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
}
 .card-image {
	 position: absolute;
	 z-index: -2;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 -o-object-fit: cover;
	 object-fit: cover;
	 transform-origin: 80% 20%;
	 transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
	 filter: brightness(1.3) contrast(0.8);
}
 .card.dark .card-image {
	 filter: brightness(0.8) contrast(0.8);
}
 .card:hover .card-image, .card:focus .card-image {
	 transform: scale(1.05);
}
@media screen and (max-width:960px) {
	.box {
		width: 500px;
		padding: 25px;
		
		position: relative;
		font-size: 22px;
		line-height: 1.6;
		transition: 0.6s;
   }
   .card {
	margin-top: 0px;
	 margin-bottom:0px;
	 width: 540px;
	 height: 350px;
}
.cite {
	position: absolute;
	left: 50%;
	margin-top: -13px;
	transform: translate(-50%, 100%);
	transition: transform 0.2s 0.075s;
	font-size: 15px;

}

.card:hover .cite, .card:focus .cite {
	transition-delay: 0.5s, 0.575s;
	letter-spacing: 0em;
	transform: translate(-50%, 20px);
}

.card:hover .box::before, .card:focus .box::before {
	width: 100%;
	transform: none;
}
.card:hover .box::after, .card:focus .box::after {
	width: 100%;
	transform: none;
}
.box-side-borders::before, .box-side-borders::after {
	transition: none;
}
.box-side-borders::before {
	bottom: 0;
	left: 0;
	width: 2px;
	height: 100%;
	transform-origin: bottom;
}
.box-side-borders::after {
	top: 0;
	right: 0;
	width: 2px;
	height: 100%;
	transform-origin: top;
}
.card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	height:100%;

	transition: none;
}
.card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
	transform:none;
	
}
.card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	transform:none;
}
.card-image {
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	transform-origin: 80% 20%;
	transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
	filter: brightness(1.3) contrast(0.8);
}
.card.dark .card-image {
	filter: brightness(0.8) contrast(0.8);
}
.card:hover .card-image, .card:focus .card-image {
	transform: scale(1.05);
}
}

@media (min-width: 490px) and (max-width: 1440px){
	*, *::before, *::after {
		box-sizing: border-box;
   }
	.card {
		position: relative;
		display: block;
		width: 640px;
		height: 450px;
		margin: 0;
		margin-top: 50px;
		margin-bottom: 50px;
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		outline: none;
		animation: slide-in 1s backwards;
	   
   }
	.card:last-child {
		animation-delay: 0.4s;
   }
	@keyframes slide-in {
		from {
			opacity: 0;
			transform: translateY(30px);
	   }
   }
	.card.dark {
		color: black;
   }
	.card.dark::before {
	   background-color:aqua;
	   color: #00001f;
   
   }
	.card::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	   background-color:aqua;
	   color: #00001f;
		opacity: 0.1;
		transition: 0.5s;
   }
	.card:hover::before, .card:focus::before {
		opacity: 0.4;
   }
	.box {
		width: 450px;
		padding: 30px;
		position: relative;
		font-size: 22px;
		line-height: 1.6;
		transition: 0.6s;
   }
	.card:hover .box, .card:focus .box {
		transform: translateY(-20px);
   }
	.text {
		
		margin: 0;
		transform: scale(0.9);
		transition: 0.5s;
		text-align: justify;
		text-transform: none;
   }
	.card:hover .text, .card:focus .text {
		transform: none;
   }
	.cite {
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%, 100%);
		transition: transform 0.2s 0.075s;
		font-size: 13px;
   
   }
	.card:hover .cite, .card:focus .cite {
		transition-delay: 0.5s, 0.575s;
		letter-spacing: 0em;
		transform: translate(-50%, 20px);
   }
	.box::before, .box-side-borders::before, .box::after, .box-side-borders::after {
		content: "";
		position: absolute;
		pointer-events: none;
		transition: 0.5s 0.1s;
		background: currentColor;
		
   }
	.box::before {
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
   }
	.box::after {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		
   }
	.card:hover .box::before, .card:focus .box::before {
		width: 390px;
		transform: translate(30px, calc(1.6em - .1em + 32px));
   }
	.card:hover .box::after, .card:focus .box::after {
		width: 390px;
		transform: translate(30px, calc(.1em - 30px));
   }
	.box-side-borders::before, .box-side-borders::after {
		transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out;
   }
	.box-side-borders::before {
		bottom: 0;
		left: 0;
		width: 2px;
		height: 100%;
		transform-origin: bottom;
   }
	.box-side-borders::after {
		top: 0;
		right: 0;
		width: 2px;
		height: 100%;
		transform-origin: top;
   }
	.card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
		height: 390px;
		transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out;
   }
	.card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
		transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
   }
	.card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
		transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
   }
	.card-image {
		position: absolute;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		transform-origin: 80% 20%;
		transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
		filter: brightness(1.3) contrast(0.8);
   }
	.card.dark .card-image {
		filter: brightness(0.8) contrast(0.8);
   }
	.card:hover .card-image, .card:focus .card-image {
		transform: scale(1.05);
   }

}
