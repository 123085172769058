
.services1 .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    gap:2rem;
    background-color: whitesmoke;
}

.services1 .box-container .box{
    background:#fff;
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    border:var(--border);
    padding: 2.5rem;
   
}

.services1 .box-container .box i{
    color: var(--green);
    font-size: 5rem;
    padding-bottom: .5rem;
    
}

.services1 .box-container .box h3{
    color: var(--black);
    font-size: 2.5rem;
    padding:1rem 0;
}

.services1 .box-container .box p{
    color: var(--light-color);
    font-size: 1.4rem;
    line-height: 2;
}
.services1 .box-container .box:hover{
    box-shadow: 0 20px 25px black;
    transform: scale(1.03);
  
}
@media screen and (max-width: 960px) {
	.services1 .box-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
        gap: 1px;
        background-color: whitesmoke;
        padding-left: 3vh;
    }
    
    .services1 .box-container .box{
        background:#fff;
        border-radius: .5rem;
        box-shadow: var(--box-shadow);
        border:var(--border);
      width: 40vh;
       
    }
    
    .services1 .box-container .box i{
        color: var(--green);
        font-size: 3rem;
        
    }
    
    .services1 .box-container .box h3{
        color: var(--black);
        font-size: 1.3rem;
      
    }
    
    .services1 .box-container .box p{
        color: var(--light-color);
       
       
    }
    .services1 .box-container .box:hover{
        box-shadow: 0 20px 25px black;
        transform: scale(1.03);
      
    }
   
}