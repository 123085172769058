@import url(https://fonts.googleapis.com/css?family=Open+sans);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+sans);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
.navbar {
  background-color:#E1E2E2; 
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}
.nav-item1 {
  display: flex;
  align-items: center;
  height: 80px;
}
.nav-links1 {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links1:hover {
  background-color: #1888ff;
  border-radius: 4px;
  
  transition: all 0.2s ease-out;
}
.nav-links {
  color: rgb(14, 3, 3);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  .nav-item1 {
    display: none;
  }
  .nav-menu.active {
    background:aqua;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

.dropdown-menu {
  background: red;
  width: 200px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 2;
 
}

.dropdown-menu li {
  background: #1888ff;
  cursor: pointer;
 
}

.dropdown-menu li:hover {
  background: #5cabff;
  
 }

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
  
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}

:root {
  --primary: #1888ff;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 18px;
  color:white;
  cursor: pointer;
  background-color: #1888ff;
  background-color: var(--primary);
  
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: black;
  border-radius: 4px;
  border: 2px solid #1888ff;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
  
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', sans-serif;
  text-transform:none;
}

.home,
.About,
.services,
.Specialities,
.contact-us,
.Appoinments,
.marketing,
.consulting {
  display: flex;

  align-items: center;
  justify-content: center;
  font-size: 3rem;
}
p{
  text-transform: none;
}
.home {
 
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  
  font-size: 100px;

}

.About {
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: red;

  font-size: 100px;
}

.services {
  
  background-position: center;
 
  background-repeat: no-repeat;
  color: red;
  font-size: 30px;
}

.Specialities {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.Appoinments {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

#preloader{
background: #000 url(/static/media/guanxian.a7b015d3.gif) no-repeat center center;
height: 100vh;
width: 100%;
position: fixed;
z-index: 100;
}

:root{
    --green:#16a085;
    --black:#444;
    --light-color:#777;
    --box-shadow:.5rem .5rem 0 rgba(22, 160, 133, .2);
    --text-shadow:.4rem .4rem 0 rgba(0, 0, 0, .2);
    --border:.2rem solid var(--green);
}

*{
    
    margin:0; padding: 0;
    box-sizing: border-box;
    outline: none; border: none;
    text-transform: capitalize;
    transition: all .2s ease-out;
    text-decoration: none;
}



.icons-container{
    display: flex;
    grid-gap:10rem;
    gap:10rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    padding-top: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
  
}

.icons-container .icons{
    border:.2rem solid #16a085;
    border:var(--border);
    box-shadow: .5rem .5rem 0 rgba(22, 160, 133, .2);
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    text-align: center;
    padding: 1.5rem;
}

.icons-container .icons i{
    font-size: 4.5rem;
    color:#16a085;
    color:var(--green);
    padding-bottom: .7rem;
}

.icons-container .icons h3{
    font-size: 4.5rem;
    color:#444;
    color:var(--black);
    padding: .5rem 0;
    text-shadow: .4rem .4rem 0 rgba(0, 0, 0, .2);
    text-shadow: var(--text-shadow);
}

.icons-container .icons p{
    font-size: 1.7rem;
    color:#777;
    color:var(--light-color);
}



.icons-container .icons:hover{
    box-shadow: 0 20px 25px black;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}



@media screen and (min-width:490px) and (max-height: 950px) {
    :root{
        --green:#16a085;
        --black:#444;
        --light-color:#777;
        --box-shadow:.5rem .5rem 0 rgba(22, 160, 133, .2);
        --text-shadow:.4rem .4rem 0 rgba(0, 0, 0, .2);
        --border:.2rem solid var(--green);
    }
    
    *{
        
        margin:0; padding: 0;
        box-sizing: border-box;
        outline: none; border: none;
        text-transform: capitalize;
        transition: all .2s ease-out;
        text-decoration: none;
    }
    
    
    
    .icons-container{
        display: flex;
        grid-gap:2rem;
        gap:2rem;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        padding-top: 2rem;
        padding-bottom: 2rem;
        cursor: pointer;
      
    }
    
    .icons-container .icons{
        border:.2rem solid #16a085;
        border:var(--border);
        box-shadow: .5rem .5rem 0 rgba(22, 160, 133, .2);
        box-shadow: var(--box-shadow);
        border-radius: .5rem;
        text-align: center;
        padding: 1.5rem;
    }
    
    .icons-container .icons i{
        font-size: 4.5rem;
        color:#16a085;
        color:var(--green);
        padding-bottom: .7rem;
    }
    
    .icons-container .icons h3{
        font-size: 4.5rem;
        color:#444;
        color:var(--black);
        padding: .5rem 0;
        text-shadow: .4rem .4rem 0 rgba(0, 0, 0, .2);
        text-shadow: var(--text-shadow);
    }
    
    .icons-container .icons p{
        font-size: 1.7rem;
        color:#777;
        color:var(--light-color);
    }
    
    
    
    .icons-container .icons:hover{
        box-shadow: 0 20px 25px black;
        -webkit-transform: scale(1.03);
                transform: scale(1.03);
    }
    
}
.appointment{
padding: 5px 5px;
 
  outline: none;
  border: none;
  font-size: 13px;
  color:white;
  cursor: pointer;
  background-color:rebeccapurple;
}

@media only screen and (min-width: 600px) {
    .container2{
        display: none;
    }
   }
   @media (min-width: 1025px) and (max-width: 1280px){
    .container2{
      display: none;
  }
}

.services1 .box-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
    grid-gap:2rem;
    gap:2rem;
    background-color: whitesmoke;
}

.services1 .box-container .box{
    background:#fff;
    border-radius: .5rem;
    box-shadow: var(--box-shadow);
    border:var(--border);
    padding: 2.5rem;
   
}

.services1 .box-container .box i{
    color: var(--green);
    font-size: 5rem;
    padding-bottom: .5rem;
    
}

.services1 .box-container .box h3{
    color: var(--black);
    font-size: 2.5rem;
    padding:1rem 0;
}

.services1 .box-container .box p{
    color: var(--light-color);
    font-size: 1.4rem;
    line-height: 2;
}
.services1 .box-container .box:hover{
    box-shadow: 0 20px 25px black;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
  
}
@media screen and (max-width: 960px) {
	.services1 .box-container{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
        grid-gap: 1px;
        gap: 1px;
        background-color: whitesmoke;
        padding-left: 3vh;
    }
    
    .services1 .box-container .box{
        background:#fff;
        border-radius: .5rem;
        box-shadow: var(--box-shadow);
        border:var(--border);
      width: 40vh;
       
    }
    
    .services1 .box-container .box i{
        color: var(--green);
        font-size: 3rem;
        
    }
    
    .services1 .box-container .box h3{
        color: var(--black);
        font-size: 1.3rem;
      
    }
    
    .services1 .box-container .box p{
        color: var(--light-color);
       
       
    }
    .services1 .box-container .box:hover{
        box-shadow: 0 20px 25px black;
        -webkit-transform: scale(1.03);
                transform: scale(1.03);
      
    }
   
}

 *, *::before, *::after {
	 box-sizing: border-box;
}
 .card {
	 position: relative;
	 display: block;
	 width: 640px;
	 height: 450px;
	 margin: 0;
	 margin-top: 50px;
	 margin-bottom: 50px;
	 color: black;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 overflow: hidden;
	 outline: none;
	 -webkit-animation: slide-in 1s backwards;
	         animation: slide-in 1s backwards;
	
}
 .card:last-child {
	 -webkit-animation-delay: 0.4s;
	         animation-delay: 0.4s;
}
 @-webkit-keyframes slide-in {
	 from {
		 opacity: 0;
		 -webkit-transform: translateY(30px);
		         transform: translateY(30px);
	}
}
 @keyframes slide-in {
	 from {
		 opacity: 0;
		 -webkit-transform: translateY(30px);
		         transform: translateY(30px);
	}
}
 .card.dark {
	 color: black;
}
 .card.dark::before {
	background-color:aqua;
	color: #00001f;

}
 .card::before {
	 content: "";
	 position: absolute;
	 z-index: -1;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	background-color:aqua;
	color: #00001f;
	 opacity: 0.1;
	 transition: 0.5s;
}
 .card:hover::before, .card:focus::before {
	 opacity: 0.4;
}
 .box {
	 width: 450px;
	 padding: 30px;
	 position: relative;
	 font-size: 22px;
	 line-height: 1.6;
	 transition: 0.6s;
}
 .card:hover .box, .card:focus .box {
	 -webkit-transform: translateY(-20px);
	         transform: translateY(-20px);
}
 .text {
	 
	 margin: 0;
	 -webkit-transform: scale(0.9);
	         transform: scale(0.9);
	 transition: 0.5s;
	 text-align: justify;
	 text-transform: none;
}
 .card:hover .text, .card:focus .text {
	 -webkit-transform: none;
	         transform: none;
}
 .cite {
	 position: absolute;
	 left: 50%;
	 top: 0;
	 -webkit-transform: translate(-50%, 100%);
	         transform: translate(-50%, 100%);
	 transition: -webkit-transform 0.2s 0.075s;
	 transition: transform 0.2s 0.075s;
	 transition: transform 0.2s 0.075s, -webkit-transform 0.2s 0.075s;
	 font-size: 20px;

}
 .card:hover .cite, .card:focus .cite {
	 transition-delay: 0.5s, 0.575s;
	 letter-spacing: 0em;
	 -webkit-transform: translate(-50%, 20px);
	         transform: translate(-50%, 20px);
}
 .box::before, .box-side-borders::before, .box::after, .box-side-borders::after {
	 content: "";
	 position: absolute;
	 pointer-events: none;
	 transition: 0.5s 0.1s;
	 background: currentColor;
	 
}
 .box::before {
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 2px;
}
 .box::after {
	 bottom: 0;
	 left: 0;
	 width: 100%;
	 height: 2px;
	 
}
 .card:hover .box::before, .card:focus .box::before {
	 width: 390px;
	 -webkit-transform: translate(30px, calc(1.6em - .1em + 32px));
	         transform: translate(30px, calc(1.6em - .1em + 32px));
}
 .card:hover .box::after, .card:focus .box::after {
	 width: 390px;
	 -webkit-transform: translate(30px, calc(.1em - 30px));
	         transform: translate(30px, calc(.1em - 30px));
}
 .box-side-borders::before, .box-side-borders::after {
	 transition: height 0.5s 0.1s, -webkit-transform 0.4s 0.2s ease-in-out;
	 transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out;
	 transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out, -webkit-transform 0.4s 0.2s ease-in-out;
}
 .box-side-borders::before {
	 bottom: 0;
	 left: 0;
	 width: 2px;
	 height: 100%;
	 -webkit-transform-origin: bottom;
	         transform-origin: bottom;
}
 .box-side-borders::after {
	 top: 0;
	 right: 0;
	 width: 2px;
	 height: 100%;
	 -webkit-transform-origin: top;
	         transform-origin: top;
}
 .card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	 height: 390px;
	 transition: height 0.3s 0.3s ease-in-out, -webkit-transform 0.5s 0.1s;
	 transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out;
	 transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out, -webkit-transform 0.5s 0.1s;
}
 .card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
	 -webkit-transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
	         transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
}
 .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	 -webkit-transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
	         transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
}
 .card-image {
	 position: absolute;
	 z-index: -2;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 object-fit: cover;
	 -webkit-transform-origin: 80% 20%;
	         transform-origin: 80% 20%;
	 transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
	 -webkit-filter: brightness(1.3) contrast(0.8);
	         filter: brightness(1.3) contrast(0.8);
}
 .card.dark .card-image {
	 -webkit-filter: brightness(0.8) contrast(0.8);
	         filter: brightness(0.8) contrast(0.8);
}
 .card:hover .card-image, .card:focus .card-image {
	 -webkit-transform: scale(1.05);
	         transform: scale(1.05);
}
@media screen and (max-width:960px) {
	.box {
		width: 500px;
		padding: 25px;
		
		position: relative;
		font-size: 22px;
		line-height: 1.6;
		transition: 0.6s;
   }
   .card {
	margin-top: 0px;
	 margin-bottom:0px;
	 width: 540px;
	 height: 350px;
}
.cite {
	position: absolute;
	left: 50%;
	margin-top: -13px;
	-webkit-transform: translate(-50%, 100%);
	        transform: translate(-50%, 100%);
	transition: -webkit-transform 0.2s 0.075s;
	transition: transform 0.2s 0.075s;
	transition: transform 0.2s 0.075s, -webkit-transform 0.2s 0.075s;
	font-size: 15px;

}

.card:hover .cite, .card:focus .cite {
	transition-delay: 0.5s, 0.575s;
	letter-spacing: 0em;
	-webkit-transform: translate(-50%, 20px);
	        transform: translate(-50%, 20px);
}

.card:hover .box::before, .card:focus .box::before {
	width: 100%;
	-webkit-transform: none;
	        transform: none;
}
.card:hover .box::after, .card:focus .box::after {
	width: 100%;
	-webkit-transform: none;
	        transform: none;
}
.box-side-borders::before, .box-side-borders::after {
	transition: none;
}
.box-side-borders::before {
	bottom: 0;
	left: 0;
	width: 2px;
	height: 100%;
	-webkit-transform-origin: bottom;
	        transform-origin: bottom;
}
.box-side-borders::after {
	top: 0;
	right: 0;
	width: 2px;
	height: 100%;
	-webkit-transform-origin: top;
	        transform-origin: top;
}
.card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	height:100%;

	transition: none;
}
.card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
	-webkit-transform:none;
	        transform:none;
	
}
.card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
	-webkit-transform:none;
	        transform:none;
}
.card-image {
	position: absolute;
	z-index: -2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	-webkit-transform-origin: 80% 20%;
	        transform-origin: 80% 20%;
	transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
	-webkit-filter: brightness(1.3) contrast(0.8);
	        filter: brightness(1.3) contrast(0.8);
}
.card.dark .card-image {
	-webkit-filter: brightness(0.8) contrast(0.8);
	        filter: brightness(0.8) contrast(0.8);
}
.card:hover .card-image, .card:focus .card-image {
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}
}

@media (min-width: 490px) and (max-width: 1440px){
	*, *::before, *::after {
		box-sizing: border-box;
   }
	.card {
		position: relative;
		display: block;
		width: 640px;
		height: 450px;
		margin: 0;
		margin-top: 50px;
		margin-bottom: 50px;
		color: black;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		outline: none;
		-webkit-animation: slide-in 1s backwards;
		        animation: slide-in 1s backwards;
	   
   }
	.card:last-child {
		-webkit-animation-delay: 0.4s;
		        animation-delay: 0.4s;
   }
	@-webkit-keyframes slide-in {
		from {
			opacity: 0;
			-webkit-transform: translateY(30px);
			        transform: translateY(30px);
	   }
   }
	@keyframes slide-in {
		from {
			opacity: 0;
			-webkit-transform: translateY(30px);
			        transform: translateY(30px);
	   }
   }
	.card.dark {
		color: black;
   }
	.card.dark::before {
	   background-color:aqua;
	   color: #00001f;
   
   }
	.card::before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	   background-color:aqua;
	   color: #00001f;
		opacity: 0.1;
		transition: 0.5s;
   }
	.card:hover::before, .card:focus::before {
		opacity: 0.4;
   }
	.box {
		width: 450px;
		padding: 30px;
		position: relative;
		font-size: 22px;
		line-height: 1.6;
		transition: 0.6s;
   }
	.card:hover .box, .card:focus .box {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
   }
	.text {
		
		margin: 0;
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
		transition: 0.5s;
		text-align: justify;
		text-transform: none;
   }
	.card:hover .text, .card:focus .text {
		-webkit-transform: none;
		        transform: none;
   }
	.cite {
		position: absolute;
		left: 50%;
		top: 0;
		-webkit-transform: translate(-50%, 100%);
		        transform: translate(-50%, 100%);
		transition: -webkit-transform 0.2s 0.075s;
		transition: transform 0.2s 0.075s;
		transition: transform 0.2s 0.075s, -webkit-transform 0.2s 0.075s;
		font-size: 13px;
   
   }
	.card:hover .cite, .card:focus .cite {
		transition-delay: 0.5s, 0.575s;
		letter-spacing: 0em;
		-webkit-transform: translate(-50%, 20px);
		        transform: translate(-50%, 20px);
   }
	.box::before, .box-side-borders::before, .box::after, .box-side-borders::after {
		content: "";
		position: absolute;
		pointer-events: none;
		transition: 0.5s 0.1s;
		background: currentColor;
		
   }
	.box::before {
		top: 0;
		left: 0;
		width: 100%;
		height: 2px;
   }
	.box::after {
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		
   }
	.card:hover .box::before, .card:focus .box::before {
		width: 390px;
		-webkit-transform: translate(30px, calc(1.6em - .1em + 32px));
		        transform: translate(30px, calc(1.6em - .1em + 32px));
   }
	.card:hover .box::after, .card:focus .box::after {
		width: 390px;
		-webkit-transform: translate(30px, calc(.1em - 30px));
		        transform: translate(30px, calc(.1em - 30px));
   }
	.box-side-borders::before, .box-side-borders::after {
		transition: height 0.5s 0.1s, -webkit-transform 0.4s 0.2s ease-in-out;
		transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out;
		transition: height 0.5s 0.1s, transform 0.4s 0.2s ease-in-out, -webkit-transform 0.4s 0.2s ease-in-out;
   }
	.box-side-borders::before {
		bottom: 0;
		left: 0;
		width: 2px;
		height: 100%;
		-webkit-transform-origin: bottom;
		        transform-origin: bottom;
   }
	.box-side-borders::after {
		top: 0;
		right: 0;
		width: 2px;
		height: 100%;
		-webkit-transform-origin: top;
		        transform-origin: top;
   }
	.card:hover .box-side-borders::before, .card:focus .box-side-borders::before, .card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
		height: 390px;
		transition: height 0.3s 0.3s ease-in-out, -webkit-transform 0.5s 0.1s;
		transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out;
		transition: transform 0.5s 0.1s, height 0.3s 0.3s ease-in-out, -webkit-transform 0.5s 0.1s;
   }
	.card:hover .box-side-borders::before, .card:focus .box-side-borders::before {
		-webkit-transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
		        transform: translate(30px, calc(-30px - 3.1em)) rotate(90deg);
   }
	.card:hover .box-side-borders::after, .card:focus .box-side-borders::after {
		-webkit-transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
		        transform: translate(-30px, calc(30px + 4.9em)) rotate(90deg);
   }
	.card-image {
		position: absolute;
		z-index: -2;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		-webkit-transform-origin: 80% 20%;
		        transform-origin: 80% 20%;
		transition: 1s cubic-bezier(0.32, 0.16, 0, 1);
		-webkit-filter: brightness(1.3) contrast(0.8);
		        filter: brightness(1.3) contrast(0.8);
   }
	.card.dark .card-image {
		-webkit-filter: brightness(0.8) contrast(0.8);
		        filter: brightness(0.8) contrast(0.8);
   }
	.card:hover .card-image, .card:focus .card-image {
		-webkit-transform: scale(1.05);
		        transform: scale(1.05);
   }

}

  .header {
    position:relative;
    text-align:center;
    background-color:white;
    color:white;
    
  }
  .logo {
    width:50px;
    fill:white;
    padding-right:15px;
    display:inline-block;
    vertical-align: middle;
    
  }
  
  .inner-header {
    height:5vh;
    width:100%;
    margin: 0;
    padding: 0;
  }
  
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .waves {
    position:relative;
    width: 100%;
    height: 30vh;
    margin-bottom:-7px; 
    min-height:100px;
    max-height:150px;
  }
  
  .content {
    position:relative;
    height:50vh;
    text-align:center;
    background-color: #35AAC0;
  }
  
  /* Animation */
  
  .parallax > use {
    -webkit-animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
            animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
  }
  @-webkit-keyframes move-forever {
    0% {
     -webkit-transform: translate3d(-90px,0,0);
             transform: translate3d(-90px,0,0);
    }
    100% { 
      -webkit-transform: translate3d(85px,0,0); 
              transform: translate3d(85px,0,0);
    }
  }
  @keyframes move-forever {
    0% {
     -webkit-transform: translate3d(-90px,0,0);
             transform: translate3d(-90px,0,0);
    }
    100% { 
      -webkit-transform: translate3d(85px,0,0); 
              transform: translate3d(85px,0,0);
    }
  }
  
  @media (max-width: 768px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:24px;
    }
    
  }




li{
    list-style: none;

 }

a{
color: black;

}
a:hover{
    color:#3500D3;
    
    font-size: 18px;
}
@-webkit-keyframes slidy {
  0%  {left: 0%;}
  10% {left: 0%;}
  15% {left: -100%;}
  35% {left: -100%;}
  40% {left: -200%;}
  60% {left: -200%;}
  65% {left: -300%;}
  85% {left: -300%;}
  90%{left: -400%;}
  100%{left: -400%;}
  }
  
  @keyframes slidy {
  0%  {left: 0%;}
  10% {left: 0%;}
  15% {left: -100%;}
  35% {left: -100%;}
  40% {left: -200%;}
  60% {left: -200%;}
  65% {left: -300%;}
  85% {left: -300%;}
  90%{left: -400%;}
  100%{left: -400%;}
  }
  
  body {
    margin: 0;
  }
  div#slider {
    overflow: hidden;
    max-width: 1200px;
    height: 800px;
    margin: 0 auto;
   
  }
  div#slider figure img {
    width: 20%;
    float: left;
  }
  div#slider figure {
    position: relative;
    width: 500%;
    margin: 0;
    left: 0;
    text-align: left;
    font-size: 0;
    -webkit-animation: 17s slidy infinite;
            animation: 17s slidy infinite;
  }
  

    @media only screen and (min-width: 600px) {
       .container2{
           display: none; 
       }
      }

.snip1515 {
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  margin: 30px;
  min-width: 230px;
  max-width: 355px;
  width: 100%;
  color: #000000;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  box-shadow: none !important;
 z-index: 1;
  
}


.snip1515 * {
  box-sizing: border-box;
  
}

.snip1515 .profile-image {
  display: inline-block;
  width: 80%;
  position: relative;
  padding: 10px;
  border: 2px solid #e8b563;
  
}

.snip1515 .profile-image img {
  max-width: 100%;
  vertical-align: top;
  
  height:200px;
}

.snip1515 figcaption {
  width: 100%;
  
  background-color: #F2F2F2;
  color: #555;
  padding: 125px 25px 25px;
  margin-top: -100px;
  display: inline-block;
}

.snip1515 h3,
.snip1515 h4,
.snip1515 p {
  margin: 0 0 5px;
}

.snip1515 h3 {
  font-weight: 600;
  font-size: 1.0em;
  font-family: 'Playfair Display', Arial, sans-serif;
}

.snip1515 h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip1515 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.snip1515 .icons {
  text-align: center;
  width: 100%;
}

.snip1515 i {
  padding: 10px 2px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #e8b563;
  opacity: 0.75;
}

.snip1515 i:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

.Oncology{
font-family: Arial, Helvetica, sans-serif;
font-size:larger;
}
.Datacontent{
    
    font-size: small;
    padding-bottom: 15px;

}

*{
margin: 0; padding:0;
box-sizing: border-box;
}

.container{
position: fixed;
top: 35vh;
z-index: 1;
}

.container .icon{
margin:0 5px;
text-align: center;
cursor: pointer;
align-items: center;
justify-content: center;
position: relative;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon span{
position: relative;
display: block;
height: 60px;
width: 60px;
background: #fff;
border-radius: 0%;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
}

.container .icon span i{
line-height: 60px;
font-size: 25px;
color: #fff;
}

.container .icon .tooltip{
position: absolute;
background: #fff;
color: #fff;
padding: 10px 18px;
font-size: 20px;
font-weight: 800;
border-radius: 0px;
top:20px;
left: 80px;
z-index: 1;
opacity: 0;
pointer-events: none;
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon:hover .tooltip{
top: -0px;
opacity: 1;
margin-top: 10px;
pointer-events: auto;
}

.icon .tooltip:before{
position: absolute;
content: "";
height: 15px;
width: 15px;
background: #fff;
left: 3px;
bottom: 15px;
-webkit-transform: translate(-50%) rotate(45deg);
        transform: translate(-50%) rotate(45deg);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .facebook span,
.container .facebook .tooltip,
.container .facebook .tooltip:before{
background: #3b5999;
color: #fff;
}

.container .youtube span,
.container .youtube .tooltip,
.container .youtube .tooltip:before{
background: #de463b;
color: #fff;
}

.container .twitter span,
.container .twitter .tooltip,
.container .twitter .tooltip:before{
background: #46c1f6;
color: #fff;
}

.container .instagram span,
.container .instagram .tooltip,
.container .instagram .tooltip:before{
background: #e1306c;
color: #fff;
}








@media screen and (max-width: 960px) {
	@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
*{
margin: 0; padding:0;
box-sizing: border-box;
}

.container{
position: fixed;
top: 35vh;
z-index: 1;
}

.container .icon{
margin:0 0px;
text-align: center;
cursor: pointer;
align-items: center;
justify-content: center;
position: relative;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon span{
position: relative;
display: block;
height: 60px;
width: 40px;
background: #fff;
border-radius: 0%;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
}

.container .icon span i{
line-height: 60px;
font-size: 25px;
color: #fff;
}

.container .icon .tooltip{
position: absolute;
background: #fff;
color: #fff;
padding: 10px 18px;
font-size: 20px;
font-weight: 800;
border-radius: 0px;
top:20px;
left: 55px;
z-index: 1;
opacity: 0;
pointer-events: none;
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon:hover .tooltip{
top: -0px;
opacity: 1;
margin-top: 10px;
pointer-events: auto;
}

.icon .tooltip:before{
position: absolute;
content: "";
height: 5px;
width: 15px;
background: #fff;
left: 3px;
bottom: 15px;
-webkit-transform: translate(-50%) rotate(45deg);
        transform: translate(-50%) rotate(45deg);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .facebook span,
.container .facebook .tooltip,
.container .facebook .tooltip:before{
background: #3b5999;
color: #fff;
}

.container .youtube span,
.container .youtube .tooltip,
.container .youtube .tooltip:before{
background: #de463b;
color: #fff;
}

.container .twitter span,
.container .twitter .tooltip,
.container .twitter .tooltip:before{
background: #46c1f6;
color: #fff;
}

.container .instagram span,
.container .instagram .tooltip,
.container .instagram .tooltip:before{
background: #e1306c;
color: #fff;
}

}

.scrolling {
	position: relative;
	width:auto;
	height: 40px;	
		
}
.scrolling pre {
	position: absolute;
	width: 100%;
	margin-top: 10px;
	font-family: 'Alegreya SC', serif;
	font-size: 2em;
	line-height: 30px;
	color:#9E15BF;
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	-webkit-animation: scrolling 25s linear infinite;
	        animation: scrolling 25s linear infinite;
	
}
pre1{
	font-family: 'Dancing Script', cursive;
	font-size: 20px;
}
pre2{
text-transform: none;
font-family: 'Oleo Script Swash Caps', cursive;
font-size: 20px;
}
pre3{
	text-transform: none;
}


@-webkit-keyframes scrolling {
	0% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
	}
}


@keyframes scrolling {
	0% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
	}
}
@media screen and (max-width: 960px) {
	.scrolling {
		position: relative;
		width:auto;
		height: 30px;
		background:white;		
	}
	.scrolling pre {
		position: absolute;
		width: 100%;
		font-family: 'Alegreya SC', serif;
		font-size: 20px;
		margin-top: -5px;
		line-height: 10px;
		padding-top: 5px;
		color:black;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		-webkit-animation: scrolling 25s linear infinite;
		        animation: scrolling 25s linear infinite;
		
	}
	@-webkit-keyframes scrolling {
		0% {
			-webkit-transform: translateX(100%);
			        transform: translateX(100%);
		}
		100% {
			-webkit-transform: translateX(-200%);
			        transform: translateX(-200%);
		}
	}
	@keyframes scrolling {
		0% {
			-webkit-transform: translateX(100%);
			        transform: translateX(100%);
		}
		100% {
			-webkit-transform: translateX(-200%);
			        transform: translateX(-200%);
		}
	}


}
.snip1515 {
  font-family: 'Open Sans', Arial, sans-serif;
  position: relative;
  margin: 30px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #000000;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  box-shadow: none !important;
 z-index: 1;
 
}

.snip1515 * {
  box-sizing: border-box;
}

.snip1515 .profile-image {
  display: inline-block;
  width: 80%;
 
  position: relative;
  padding: 10px;
  border: 2px solid #e8b563;
  
}

.snip1515 .profile-image img {
  max-width: 100%;
  vertical-align: top;
  z-index: 1;
}

.snip1515 figcaption {
  width: 100%;
  
  background-color: #F2F2F2;
  color: #555;
  padding: 125px 25px 25px;
  margin-top: -100px;
  display: inline-block;
}

.snip1515 h3,
.snip1515 h4,
.snip1515 p {
  margin: 0 0 5px;
}

.snip1515 h3 {
  font-weight: 600;
  font-size: 1.3em;
  font-family: 'Playfair Display', Arial, sans-serif;
}

.snip1515 h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip1515 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

.snip1515 .icons {
  text-align: center;
  width: 100%;
}

.snip1515 i {
  padding: 10px 2px;
  display: inline-block;
  font-size: 18px;
  font-weight: normal;
  color: #e8b563;
  opacity: 0.75;
}

.snip1515 i:hover {
  opacity: 1;
  transition: all 0.35s ease;
}

:root {
  --card-color: #1b4cc8;
}


.pricing {
  padding: 60px 0;
  background: #fff;
 
}
.pricing__grid {
  display: grid;
  grid-gap: 30px;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.pricing__title {
  font-size: 48px;
  font-weight: 900;
  text-align: center;
  color: #444;
  margin-bottom: 60px;
  line-height: 1;
  font-family: 'Libre Bodoni', serif;
}
.pricing-card {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background: #edf2ff;
  border: 1px solid transparent;
  transition: all 0.6s ease;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
}
.pricing-card:hover {
  -webkit-transform: perspective(1000px) scale(0.97) translateZ(10px) rotateY(-10deg);
          transform: perspective(1000px) scale(0.97) translateZ(10px) rotateY(-10deg);
  border-color: #1b4cc8;
}
.pricing-card:hover .pricing-card__top {
  -webkit-transform: perspective(1000px) translateZ(5px) scale(1.01) translateX(-20px) translateY(-15px);
          transform: perspective(1000px) translateZ(5px) scale(1.01) translateX(-20px) translateY(-15px);
  opacity: 0.9;
}
.pricing-card:hover .pricing-card__button a {
  -webkit-transform: perspective(1000px) translateZ(5px) scale(1.01) translateX(-15px) translateY(5px);
          transform: perspective(1000px) translateZ(5px) scale(1.01) translateX(-15px) translateY(5px);
}
.pricing-card__top {
  transition: all 0.6s ease;
  border-radius: 15px;
  background: #1b4cc8;
  background: var(--card-color);
  position: relative;
  padding: 20px 30px;
  color: #fff;
  display: flex;
  flex-direction: column;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
}
.pricing-card__title {
  
  margin-bottom: 80px;
  font-family: 'Alegreya SC', serif;
	font-size: 2em;
}
.pricing-card__price {
  font-size: 700;
  font-size: 24px;
  align-self: flex-end;
}
.pricing-card__price span {
  font-weight: 300;
  font-size: 14px;
}
.pricing-card__body {
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.pricing-card__pluses {
  flex: 1 1;
  margin-bottom: 30px;
}
.pricing-card__plus {
  font-size: 16px;
  color: #444;
  display: flex;
  align-items: center;
  font-weight: 300;
}
.pricing-card__plus:not(:last-child) {
  margin-bottom: 10px;
}
.pricing-card__plus-icon {
  margin-right: 15px;
  line-height: 1;
  color: #1b4cc8;
  color: var(--card-color);
}
.pricing-card__plus-text,
.pricing-card__button {
  display: flex;
  justify-content: flex-end;
}
.pricing-card_free {
  --card-color: #3868e4;
}
.pricing-card_premium {
  --card-color: #163da0;
 
}

