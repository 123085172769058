.appointment{
padding: 5px 5px;
 
  outline: none;
  border: none;
  font-size: 13px;
  color:white;
  cursor: pointer;
  background-color:rebeccapurple;
}

@media only screen and (min-width: 600px) {
    .container2{
        display: none;
    }
   }
   @media (min-width: 1025px) and (max-width: 1280px){
    .container2{
      display: none;
  }
}