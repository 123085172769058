
:root{
    --green:#16a085;
    --black:#444;
    --light-color:#777;
    --box-shadow:.5rem .5rem 0 rgba(22, 160, 133, .2);
    --text-shadow:.4rem .4rem 0 rgba(0, 0, 0, .2);
    --border:.2rem solid var(--green);
}

*{
    
    margin:0; padding: 0;
    box-sizing: border-box;
    outline: none; border: none;
    text-transform: capitalize;
    transition: all .2s ease-out;
    text-decoration: none;
}



.icons-container{
    display: flex;
    gap:10rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    padding-top: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
  
}

.icons-container .icons{
    border:var(--border);
    box-shadow: var(--box-shadow);
    border-radius: .5rem;
    text-align: center;
    padding: 1.5rem;
}

.icons-container .icons i{
    font-size: 4.5rem;
    color:var(--green);
    padding-bottom: .7rem;
}

.icons-container .icons h3{
    font-size: 4.5rem;
    color:var(--black);
    padding: .5rem 0;
    text-shadow: var(--text-shadow);
}

.icons-container .icons p{
    font-size: 1.7rem;
    color:var(--light-color);
}



.icons-container .icons:hover{
    box-shadow: 0 20px 25px black;
    transform: scale(1.03);
}



@media screen and (min-width:490px) and (max-height: 950px) {
    :root{
        --green:#16a085;
        --black:#444;
        --light-color:#777;
        --box-shadow:.5rem .5rem 0 rgba(22, 160, 133, .2);
        --text-shadow:.4rem .4rem 0 rgba(0, 0, 0, .2);
        --border:.2rem solid var(--green);
    }
    
    *{
        
        margin:0; padding: 0;
        box-sizing: border-box;
        outline: none; border: none;
        text-transform: capitalize;
        transition: all .2s ease-out;
        text-decoration: none;
    }
    
    
    
    .icons-container{
        display: flex;
        gap:2rem;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
        padding-top: 2rem;
        padding-bottom: 2rem;
        cursor: pointer;
      
    }
    
    .icons-container .icons{
        border:var(--border);
        box-shadow: var(--box-shadow);
        border-radius: .5rem;
        text-align: center;
        padding: 1.5rem;
    }
    
    .icons-container .icons i{
        font-size: 4.5rem;
        color:var(--green);
        padding-bottom: .7rem;
    }
    
    .icons-container .icons h3{
        font-size: 4.5rem;
        color:var(--black);
        padding: .5rem 0;
        text-shadow: var(--text-shadow);
    }
    
    .icons-container .icons p{
        font-size: 1.7rem;
        color:var(--light-color);
    }
    
    
    
    .icons-container .icons:hover{
        box-shadow: 0 20px 25px black;
        transform: scale(1.03);
    }
    
}