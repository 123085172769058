
.scrolling {
	position: relative;
	width:auto;
	height: 40px;	
		
}
.scrolling pre {
	position: absolute;
	width: 100%;
	margin-top: 10px;
	font-family: 'Alegreya SC', serif;
	font-size: 2em;
	line-height: 30px;
	color:#9E15BF;
	transform: translateX(100%);
	animation: scrolling 25s linear infinite;
	
}
pre1{
	font-family: 'Dancing Script', cursive;
	font-size: 20px;
}
pre2{
text-transform: none;
font-family: 'Oleo Script Swash Caps', cursive;
font-size: 20px;
}
pre3{
	text-transform: none;
}


@keyframes scrolling {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}
@media screen and (max-width: 960px) {
	.scrolling {
		position: relative;
		width:auto;
		height: 30px;
		background:white;		
	}
	.scrolling pre {
		position: absolute;
		width: 100%;
		font-family: 'Alegreya SC', serif;
		font-size: 20px;
		margin-top: -5px;
		line-height: 10px;
		padding-top: 5px;
		color:black;
		transform: translateX(100%);
		animation: scrolling 25s linear infinite;
		
	}
	@keyframes scrolling {
		0% {
			transform: translateX(100%);
		}
		100% {
			transform: translateX(-200%);
		}
	}


}