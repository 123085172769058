@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
*{
margin: 0; padding:0;
box-sizing: border-box;
}

.container{
position: fixed;
top: 35vh;
z-index: 1;
}

.container .icon{
margin:0 5px;
text-align: center;
cursor: pointer;
align-items: center;
justify-content: center;
position: relative;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon span{
position: relative;
display: block;
height: 60px;
width: 60px;
background: #fff;
border-radius: 0%;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
}

.container .icon span i{
line-height: 60px;
font-size: 25px;
color: #fff;
}

.container .icon .tooltip{
position: absolute;
background: #fff;
color: #fff;
padding: 10px 18px;
font-size: 20px;
font-weight: 800;
border-radius: 0px;
top:20px;
left: 80px;
z-index: 1;
opacity: 0;
pointer-events: none;
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon:hover .tooltip{
top: -0px;
opacity: 1;
margin-top: 10px;
pointer-events: auto;
}

.icon .tooltip:before{
position: absolute;
content: "";
height: 15px;
width: 15px;
background: #fff;
left: 3px;
bottom: 15px;
transform: translate(-50%) rotate(45deg);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .facebook span,
.container .facebook .tooltip,
.container .facebook .tooltip:before{
background: #3b5999;
color: #fff;
}

.container .youtube span,
.container .youtube .tooltip,
.container .youtube .tooltip:before{
background: #de463b;
color: #fff;
}

.container .twitter span,
.container .twitter .tooltip,
.container .twitter .tooltip:before{
background: #46c1f6;
color: #fff;
}

.container .instagram span,
.container .instagram .tooltip,
.container .instagram .tooltip:before{
background: #e1306c;
color: #fff;
}








@media screen and (max-width: 960px) {
	@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
*{
margin: 0; padding:0;
box-sizing: border-box;
}

.container{
position: fixed;
top: 35vh;
z-index: 1;
}

.container .icon{
margin:0 0px;
text-align: center;
cursor: pointer;
align-items: center;
justify-content: center;
position: relative;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon span{
position: relative;
display: block;
height: 60px;
width: 40px;
background: #fff;
border-radius: 0%;
z-index: 2;
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
}

.container .icon span i{
line-height: 60px;
font-size: 25px;
color: #fff;
}

.container .icon .tooltip{
position: absolute;
background: #fff;
color: #fff;
padding: 10px 18px;
font-size: 20px;
font-weight: 800;
border-radius: 0px;
top:20px;
left: 55px;
z-index: 1;
opacity: 0;
pointer-events: none;
box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .icon:hover .tooltip{
top: -0px;
opacity: 1;
margin-top: 10px;
pointer-events: auto;
}

.icon .tooltip:before{
position: absolute;
content: "";
height: 5px;
width: 15px;
background: #fff;
left: 3px;
bottom: 15px;
transform: translate(-50%) rotate(45deg);
transition: 0.4s cubic-bezier(0.52, -0.50, 0.212, 1.45);
}

.container .facebook span,
.container .facebook .tooltip,
.container .facebook .tooltip:before{
background: #3b5999;
color: #fff;
}

.container .youtube span,
.container .youtube .tooltip,
.container .youtube .tooltip:before{
background: #de463b;
color: #fff;
}

.container .twitter span,
.container .twitter .tooltip,
.container .twitter .tooltip:before{
background: #46c1f6;
color: #fff;
}

.container .instagram span,
.container .instagram .tooltip,
.container .instagram .tooltip:before{
background: #e1306c;
color: #fff;
}

}