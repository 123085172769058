.Oncology{
font-family: Arial, Helvetica, sans-serif;
font-size:larger;
}
.Datacontent{
    
    font-size: small;
    padding-bottom: 15px;

}
